import React, { useEffect, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { SmileOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useParams, useSearchParams } from "react-router-dom";

export default function StripeResponse(props) {
  const { id } = useParams()
  const stripe = useStripe();
  const elements = useElements();
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const openNotification = (message) => {
    api.open({
      message: 'Invited',
      description: message,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };
  
  useEffect(() => {
    if (!stripe) {
      return;
    }
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    
    if (!clientSecret) {
      return;
    }
    
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          //acceptInvite();
          props.actionProvider.showBets()
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  
  
  const paymentElementOptions = {
    layout: "tabs"
  }
  
  return null;
}
