import './App.css';
import { useEffect } from "react";

function App() {
  useEffect(() => {
    console.log('redirec')
  }, []);
  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#ffffff'}}>
      <div className="Faqs">
        <h1 style={{ width: '100%', textAlign: 'center' }}>Pickify Golf Bot powered by ForeCaddy FAQ</h1>
        <div style={{ marginTop: 40 }}><strong>1. What is ForeCaddy and how does it generate picks?</strong></div>
        <div className={'m10'}>
          <strong>a.</strong> ForeCaddy is a machine learning predictive model for professional golf
          created by Joe Clements at Quant Solutions Gaming. Joe was responsible
          for building Pinnacle Sports’ golf product from scratch back between 2019-
          2023. ForeCaddy’s algorithms simulate out all 72 holes for every active
          golfer in the field of a professional golf event, and generate predictions
          based on millions of data points. The algorithms then use these
          predictions and compare them against the offered odds at over 15
          different sportsbooks and identifies only the wagers in which the model
          believes are good value bets. These are the bets that are then made
          available through Pickify
        </div>
        <div style={{ marginTop: 40 }}><strong>2. What types of bets will be available via Pickify’s Golf Bot?</strong>
        </div>
        <div className={'m10'}>
          <strong>a.</strong> ForeCaddy will generate bets on the following markets each day before
          the tournament starts, and between each round of the tournament. A
          description is described for each wager type:
          <div className="m5 m10 " style={{ marginTop: 30 }}>
            i. Win – wager that the selected golfer will win the tournament,
            including playoffs
          </div>
          <div className="m5">
            ii. Top 5 – wager that the selected golfer will finish in the top 5 with
            ties.
          </div>
          
          <div className="m5">
            iii. Top 10 - wager that the selected golfer will finish in the top
            10 with ties.
          </div>
          <div className="m5">
            iv. Top 20 - wager that the selected golfer will finish in the top
            20 with ties.
          </div>
          <div className="m5">
            v. Make The Cut – wager that the selected golfer will make the cut
            based on the cut rules of the tournament
          </div>
          <div className="m5">
            vi. Round Matchups – 18 hole head-to-head matchup between
            two golfers for the lowest score. If the round results in a tie, the
            wager is a push.
          </div>
          <div className="m5">
            vii. Tournament Matchups – 72 hole, head-to-head matchup
            between two golfers for the lowest score. If one golfer withdrawals,
            gets DQ’d, or fails to make the cut, the golfer who plays the most
            holes in the tournament wins the bet. If both golfers miss the cut,
            the one with the lowest score wins.
          </div>
        </div>
        <div style={{ marginTop: 40 }}><strong>3. What do the fields/information provided in each pick mean and how can
          I use the
          information when making the bet?</strong></div>
        <div className={'m10'}>
          <strong>a.</strong> The pick will be displayed in the following format:
          <div className="m5 m10 " style={{ marginTop: 10, marginBottom: 20 }}>
            i. Player Name / Bet Type / Odds / Bet Size in units (u)
          </div>
          <strong>b.</strong> Bet size in units represents how much ForeCaddy recommends wagering
          given the calculated edge of the wager, and the likelihood of the wager.
          Given that matchups are closer to 50/50 propositions than a golfer to win,
          for example, the recommended wager size will always be larger for
          matchups. Bet Size in units is represented as a percentage of the average
          bet size of the customer. If the customer generally bets $100, 0.1u
          represents a 10% of $100 or $10 wager for that customer.
        </div>
      </div>
    </div>
  );
}

export default App;
