import React from "react";

import { ReactComponent as FlightIcon } from "../icons/plane-alt.svg";

const FlightBotAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <img src="/logo-icon.png" width={70}/>
      </div>
    </div>
  );
};

export default FlightBotAvatar;
