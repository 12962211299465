import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from "./error-page";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter, redirect,
  RouterProvider,
} from "react-router-dom";
import Home from "./home";
import Error from "./error";
import Faqs from "./faqs";
import Chatbot from "react-chatbot-kit";
import config from "./config";
import nflConfig from "./nflConfig";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import PaymentOption from "./widgets/PaymentOptions/PaymentOptions";
import Bets from "./Bets";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "*",
    element: <Error/>,
    loader: async ({ request, params }) => {
      return redirect('/golf');
    },
  },
  {
    path: "/faqs",
    element: <Faqs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/nba",
    element: (
      <div>
        <Chatbot
          config={config}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
        />
      </div>
    ),
  },
  {
    path: "/nfl",
    element: (
      <div>
        <Chatbot
          config={nflConfig}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
        />
      </div>
    ),
  },
  {
    path: "/payment",
    element:  <PaymentOption/>,
  },
  {
    path:"/bets/:id",
    element: <Bets/>,
  }
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
