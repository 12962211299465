import logo from './logo.svg';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import './App.css';
import PaymentOption from "./widgets/PaymentOptions/PaymentOptions";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Bets from "./Bets";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Chatbot
          config={config}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
        />
      </div>
    ),
  },
  {
    path: "/payment",
    element:  <PaymentOption/>,
  },
  {
    path:"/bets/:id",
    element: <Bets/>,
  }
]);

function App() {
  return (
    <div className="App" style={{height: '100vh'}}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
