import React from "react";

import "./Options.css";

const Options = props => {
  return (
    <div className="options">
      <h1 className="options-header">{props.title}</h1>
      <div className="options-container">
        {Array.isArray(props?.options) && props?.options?.map(option => {
          return (
            <div
              className="option-item"
              onClick={()=>{option.handler(option)}}
              key={option.id}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Options;
