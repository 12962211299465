import React, { useEffect, useState } from "react";

import Options from "../Options/Options";
import { getGamesData } from "../../data/data";
import { Spin } from "antd";

const GamesOptions = props => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    getGames()
  }, []);
  const getGames = async ()=>{
    const games = await getGamesData(props.state.selectedAirport.iata);
    const _options = games.slice(0,8).map((game, index)=>({
      name: `(${index+1}) ${game.home_team}-${game.away_team}`,
      id: game.id,
      handler:(option)=>{
       props.actionProvider.handleGameSelect(option.id)
      }
    }))
    setGames(_options);
  }
  if(games.length === 0){
    return <Spin size="large" />
  }
  return <Options options={games} title="" {...props} />;
};

export default GamesOptions;
