import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import StripeResponse from "../../StripeRrsponse";
import { useParams, useSearchParams } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_live_51PvipEAMLgwODjpcyVCX0Amh4EKbg8hg9WfcYxFzD6wEr8IAL6Eru47RqOErthk0HK3TyQT9pAXo176dtdfl7sEc00Azg8P8LE");

function ProcessPayment(props) {
  const { id } = useParams()
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [clientSecret, setClientSecret] = useState("");
  const openNotification = (message) => {
    api.open({
      message: 'Invited',
      description: message,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };
  
  useEffect(() => {
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    
    if (!clientSecret) {
      return;
    }
    setClientSecret(clientSecret)
    
  }, []);
  
  if(!clientSecret){
    return <div></div>
  }
  
  
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1840CE',
    }
  };
  const options = {
    clientSecret,
    appearance,
  };
  
  return (
    <div>
      <Elements options={options} stripe={stripePromise}>
        <StripeResponse {...props} />
      </Elements>
    </div>
  );
}
export default ProcessPayment;
