import './App.css';
import { useEffect } from "react";
import { Link } from "react-router-dom";

function App() {
  useEffect(() => {
    console.log('redirec',window.location.origin)
  }, []);
  return (
    <div className="home">
      <img style={{width: 300}} src="logo.png"/>
      <div className="homelinks">
        <Link to={'/nfl'}>nfl bot</Link>
      </div>
    </div>
  );
}

export default App;
