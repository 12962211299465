const baseURL = "https://atsl2nsysg.execute-api.eu-north-1.amazonaws.com";
const environment = "dev";

export const getFlightsData = async (iata, direction) => {
  let data = await fetch(
    `${baseURL}/${environment}/flights?iata=${iata}&direction=${direction}`
  );
  data = await data.json();
  return data.flights.Flights;
};

export const getGamesData = async (type='nba') => {
  try {
    const game = type === 'nba' ? 'basketball_nba' : 'americanfootball_nfl';
    let response = await fetch(
      `https://us-central1-livelock-92cfa.cloudfunctions.net/api/api/categories/the-odds?game=${game}`
    );
    response = await response.json();
    if(type === 'nfl'){
      return response.games.filter((game)=>game.id === 'd94f808523ced7460f7c88c758c481f5')
    }
    return response.games;
  }catch (e) {
    console.log('e',e)
  }
};
export const geBetsData = async (id) => {
  try {
    let response = await fetch(
      `https://us-central1-livelock-92cfa.cloudfunctions.net/api/api/categories/get-bets/${id}`
    );
    response = await response.json();
    return response.bets;
  }catch (e) {
    console.log('e',e)
  }
};
